import Carousel from "react-bootstrap/Carousel";

function Slides(){
    const slides = [];

    const directoryImages = window.location+"/images/"

    slides.push({"title":"Floral Clean Produtos de Limpeza","image":"loja-1.jpg"});
    slides.push({"title":"A loja de produtos de limpeza mais fofa de Joinville !","image":"loja-1.jpg"});
    slides.push({"title":"Rua Piauí, 267 - Bucarein - Joinville / SC","image":"loja-3.jpg"});
    slides.push({"title":"Fone: (47) 3511-3477","image":"loja-4.jpg"});
    slides.push({"title":"WhatsApp: (47) 3511-3477","image":"loja-5.jpg"});
    slides.push({"title":"vendas@floralclean.com.br","image":"loja-1.jpg"});

    const alturaSlide = 350;

    return (
            <Carousel  style={{height:alturaSlide}} interval={3000} fade>
                {slides.map(({title,image},i) => (
                    <Carousel.Item key={i}>
                        <img className="d-block w-100"  src={directoryImages+image}  alt={title} title={title}/>
                        <Carousel.Caption>
                            <h3 className="d-block text-decoration-none text-black bg-light bg-opacity-75 p-3">{title}</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
    )
}
export default Slides;