import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Image} from "react-bootstrap";
import Slides from "./components/slides/index.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";

function App() {
  return (
      <Container>
          <Container fluid className="text-center">
              <Image src={window.location+"/images/logo.png"} height={150} style={{margin:20}}/>
          </Container>
          <Slides/>
          <a
              href="https://wa.me/554735113477"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
          >
              <FontAwesomeIcon icon={faWhatsapp}/>
          </a>
          <a
              href="https://www.instagram.com/floralclean_oficial/"
              className="instagram_float"
              target="_blank"
              rel="noopener noreferrer"
          >
              <FontAwesomeIcon icon={faInstagram}/>
          </a>
      </Container>
  );
}

export default App;
